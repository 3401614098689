<template>
  <PxHeader :activeSearchMobile="false" />

  <section v-if="isBusy3" class="containerGifLoading">
    <img src="@/assets/images/loaderagrogo.gif" class="sizeGifLoading" alt="" />
  </section>
  <section v-else class="">
    <publish-button />
    <img
      class="hidden md:flex object-cover min-h-contain-verifycellphone w-full"
      :src="loadImg(record.img_head_url)"
      alt=""
    />
    <div class="container-bg-passwordrecovery-desktop">
      <div
        class="bg-agrogo-green-2 bg-agrogo-green-2-transparent sizeMinAllView"
      >
        <div class="container pt-4 md:pt-20">
          <div class="grid grid-cols-1" v-if="!isVisibled">
            <div class="text-left">
              <p
                class="text-base text-white font-nexa font-normal ml-16 mr-10 mb-4 md:text-2xl lg:text-3xl md:ml-16 md:mr-16 md:mb-16"
              >
                Verificación de número telefónico
              </p>
              <form-password-recovery
                :isBusy="isBusy"
                @send="send"
                :telephone="telephone"
              />
              <div class="mx-8" v-if="errors.length > 0">
                <div class="h-0.5 bg-agrogo-yellow-1"></div>
                <div
                  class="flex items-center justify-center bg-agrogo-green-5 py-2.5 px-3 mb-4 md:min-h-94 md:mb-12"
                >
                  <p
                    v-for="(item, index) in errors"
                    :key="index"
                    class="text-xs font-dinpro font-normal text-agrogo-gray-1 md:text-xl"
                  >
                    {{ item.name }}
                  </p>
                  <!-- <p
                    class="text-xs font-dinpro font-normal text-agrogo-gray-1 md:text-xl"
                  >
                    Tu código ha sido enviado a
                    <span
                      class="icon-whatsapp font-dinpro font-normal text-white"
                    >
                      <span class="font-dinpro font-medium text-white">
                        Whatsapp</span
                      ></span
                    >
                  </p> -->
                </div>
              </div>
            </div>
          </div>
          <form-code
            v-else
            :isBusy="isBusy2"
            :errors="errors2"
            :codeReset="codeReset"
            @validateCode="validateCode"
            @resendCode="resendCode"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { generateHash } from "@/utils";
import { API_ROUTES } from "@/config";
// Mixins
import alertMessageMixin from "@/mixins/alertMessageMixin";
// components
import PxHeader from "@/components/header/PxHeader";
import PublishButton from "@/components/publishButton/PublishButton";
import FormPasswordRecovery from "./components/FormPasswordRecovery";
import FormCode from "./components/FormCode";

export default {
  inject: ["provider"],
  mixins: [alertMessageMixin],
  components: {
    PxHeader,
    PublishButton,
    FormPasswordRecovery,
    FormCode,
  },
  data() {
    const me = this;
    return {
      repository: me.provider.verifyTelephoneRepository,
      publishFarmRepository: this.provider.publishFarmRepository,
      telephone: me.$route.query.telephone ? me.$route.query.telephone : "",
      isBusy: false,
      isBusy2: false,
      isBusy3: true,
      isVisibled: false,
      errors: [],
      errors2: [],
      codesWp: [],
      code_by_wpp: null,
      formData: null,
      codeReset: false,
      record: null,
    };
  },
  methods: {
    async send(formData) {
      const me = this;
      try {
        me.isBusy = true;
        me.formData = formData;
        const dataAditional = await generateHash();
        await me.repository.save({
          ...dataAditional,
          ...formData,
        });
        const message =
          "Se ha enviado un código de verificación a tu Whatsapp.";
        me.resendCode(message);
        me.isVisibled = true;
      } catch (error) {
        me.errors = [];
        const { response } = error.response.data;
        me.errors.push({
          name: response,
        });
      } finally {
        me.isBusy = false;
      }
    },
    async validateCode(code) {
      const me = this;
      try {
        me.isBusy2 = true;
        me.codeReset = false;
        me.code_by_wpp = code;
        const dataAditional = await generateHash();
        await me.repository.validateCode({
          ...dataAditional,
          ...me.formData,
          code_by_wpp: me.code_by_wpp,
        });
        me.$router.push("/registro-exitoso");
      } catch (error) {
        const code = error.response.status;
        me.errors2 = [];

        if (code == 403) {
          me.codeReset = true;
          me.errors2.push({
            name: "Código invalido.",
          });
        } else if (code == 401) {
          const message =
            "El código ha vencido, por favor solicite un nuevo código de verificación.";
          me.resendCode(message);
        } else {
          const { message } = error.response.data;
          me.errors2.push({
            name: message,
          });
        }
      } finally {
        me.isBusy2 = false;
      }
    },
    resendCode(message) {
      const me = this;
      me.alertMenssage(message, 3000);
      me.isVisibled = false;
    },
    format(type, number) {
      const me = this;

      let attribute = null;
      let code = number + "";

      if (code) {
        let t = code.toString();
        let regex = /\d{1}/;
        attribute = t.match(regex)[0];
      } else {
        attribute = code;
      }

      if (type == "1") {
        me.formData.code1 = attribute;
      } else if (type == "2") {
        me.formData.code2 = attribute;
      } else if (type == "3") {
        me.formData.code3 = attribute;
      } else {
        me.formData.code4 = attribute;
      }
    },
    async loadData() {
      const me = this;
      try {
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.publishFarmRepository.getAllNames(
          "PASSWORD_RECOVERY",
          dataAditional
        );
        me.record = data;
      } catch (error) {
        // console.log(error);
      } finally {
        me.isBusy3 = false;
      }
    },
    loadImg(url) {
      let img = null;
      img = url
        ? `${API_ROUTES.image.get}${url}`
        : require("@/assets/images/paisajeaboutusdesktop.jpg");
      return img;
    },
  },
  mounted() {
    const me = this;
    window.scroll(0, 0);
    me.loadData();
  },
};
</script>
